.pp-paper {
	text-align: center !important;
}

.cp-form {
	width: 100%;
	margin-top: 10px;
}

.cp-submit {
	margin: 30px 0px !important;
}
