:root {
	--red: #bd2e1f;
	--white: #fff;
	--gray: #d3d3d3;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Black.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-BlackItalic.ttf) format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Bold.ttf) format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-BoldItalic.ttf) format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-ExtraBold.ttf) format("truetype");
	font-weight: bolder;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-ExtraBoldItalic.ttf) format("truetype");
	font-weight: bolder;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-ExtraLight.ttf) format("truetype");
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-ExtraLightItalic.ttf) format("truetype");
	font-weight: lighter;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Italic.ttf) format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Light.ttf) format("truetype");
	font-weight: light;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-LightItalic.ttf) format("truetype");
	font-weight: light;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Medium.ttf) format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-MediumItalic.ttf) format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Regular.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-SemiBold.ttf) format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(../fonts/Poppins-SemiBoldItalic.ttf) format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-Thin.ttf) format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url(../fonts/Poppins-ThinItalic.ttf) format("truetype");
	font-weight: 100;
	font-style: italic;
}

body {
	margin: 0;
	font-family: Poppins !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f5f5;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.b-red {
	background-color: var(--red) !important;
}

.opacity-85 {
	opacity: 0.85;
}

.t-white {
	color: var(--white) !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.subnav {
	background-color: var(--gray) !important;
}

.table-header {
	border-bottom: 2px solid var(--red) !important;
}

.app-loader {
	width: 50px !important;
	height: 50px !important;
}

.app-loading-div {
	text-align: center;
	margin-top: 100px;
}

.padding-36 {
	padding: 36px !important;
}

.padding-top-24 {
	padding-top: 24px !important;
}

.padding-top-12 {
	padding-top: 12px !important;
}

.tab-container {
	padding: 24px 24px 48px 24px;
}

.margin-top-24 {
	margin-top: 24px !important;
}

.padding-bottom-24 {
	padding-bottom: 24px !important;
}

.padding-bottom-48 {
	padding-bottom: 48px !important;
}

.full-width {
	width: 100% !important;
}

.toggle-button.Mui-selected {
	background-color: var(--red) !important;
	color: var(--white) !important;
	font-weight: bolder;
}

.toggle-button {
	background-color: var(--white) !important;
	border: 1px solid var(--gray) !important;
	color: black !important;
}

.velo-chart-circle {
	cursor: pointer;
}

.velo-chart-circle:hover {
	cursor: pointer;
	stroke: black;
	stroke-width: 1px;
	opacity: 0.75;
}

.tooltip-div {
	width: 200px !important;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 20px;
	text-align: left;
}

.tooltip-value {
	font-weight: bolder;
	text-align: right;
}

.hover-pointer:hover {
	cursor: pointer;
}

.bold {
	font-weight: bold !important;
}
