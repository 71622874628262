.pitching-roster-table-title {
	font-size: 24px;
	font-weight: bold;
	padding: 24px 0px 12px 24px;
}

.pitching-roster-table-add {
	text-align: right;
	padding: 24px 24px 12px 24px;
}
