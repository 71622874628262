@media (max-width: 600px) {
	.admin-users-table-title {
		font-size: 24px !important;
	}
}

@media (max-width: 380px) {
	.admin-users-add-user-button {
		padding-top: 25px !important;
	}
}

@media (min-width: 381px) {
	.admin-users-add-user-button {
		padding-top: 30px !important;
	}
}
