.pt-pitches-inning-title {
	font-size: 36px;
	text-decoration: underline;
	text-decoration-color: var(--red);
}

.pt-pitches-inning-droppable {
	width: 100%;
	/* min-height: 100px; */
}

.pt-pitches-edit-ab-input-lg {
	width: 90%;
	margin: 12px 0px !important;
}
.pt-pitches-edit-ab-inplay {
	width: 90% !important;
}
.pt-pitches-edit-ab-input-sm {
	margin: 12px 0px !important;
	width: 90%;
}
.pt-pitches-edit-spray-field {
	padding-top: 24px;
	width: 70%;
	margin-left: 15%;
	text-align: center;
	cursor: pointer;
}
.pt-pitches-edit-spray-field:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}
.pt-pitches-edit-spray-field:active {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}

.pt-pitches-ab-card {
	padding: 24px !important;
}

.pt-pitches-ab-field-name {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.5);
}

.pt-pitches-ab-field-value {
	font-size: 20px;
	font-weight: bolder;
}

.pt-pitches-ab-hit-hard-container {
	padding-top: 6px;
}

@media (max-width: 959px) {
	.pt-pitches-edit-ab-buttons {
		text-align: center;
	}
	.pt-pitches-edit-ab-input-lg {
		width: 70%;
		margin: 12px 0px !important;
	}
	.pt-pitches-edit-ab-inplay {
		width: 70% !important;
		margin-left: 15% !important;
	}
	.pt-pitches-edit-ab-input-sm {
		margin: 12px 0px !important;
		width: 90%;
	}
	.pt-pitches-edit-spray-button {
		margin-top: 12px !important;
		width: 70%;
		margin-left: 15%;
	}
	.pt-pitches-edit-ab-main {
		text-align: center;
	}
}

.pt-pitches-tab-container {
	padding-top: 12px !important;
}

@media (max-width: 599px) {
	.pt-pitches-ab-card-traj-container {
		padding-top: 12px;
	}
	.pt-pitches-ab-card-result {
		padding-top: 0px !important;
	}
}

.pt-pitches-ab-card-result {
	padding-top: 6px;
}

.pt-pitches-header-paper {
	border-bottom: 1px solid var(--gray);
}

.pt-pitches-header {
	width: 100% !important;
	height: 80px;
	padding: 12px 0px;
}

.pt-pitches-exit-button-div {
	padding-top: 10px;
}

#pt-pitches-header-pitcher-name {
	font-weight: bold;
}

.pt-pitches-zone:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.pt-pitches-zone:active {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}
