.login-paper {
	top: 50% !important;
	left: 50% !important;
	padding: 72px 36px;
	transform: translate(-50%, -50%);
	display: flex;
	position: absolute;
	width: 500px;
	border-radius: 16px !important;
}

.login-submit-button-div {
	margin-top: 24px !important;
}

@media (max-width: 600px) {
	.login-paper {
		width: 70%;
	}
}

.login-logo {
	width: 100px;
	height: auto;
}
