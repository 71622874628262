.pitcher-title-paper {
	padding: 24px 0px;
	font-weight: bold;
}

.pitcher-title-name {
	font-size: 32px;
}

.pitcher-title-outing-type-fc {
	background-color: white;
}
