.pt-location-stats-paper {
	font-size: 16px;
	padding: 11px 5px;
	border: 1px solid var(--gray) !important;
}

.pt-location-stat-value {
	font-weight: bold;
}

.pt-location-stat-name {
	color: rgba(0, 0, 0, 0.5);
}

.pt-location {
	width: 512px !important;
	height: 46px !important;
	padding-left: calc(50% - 500px) !important;
	padding-right: 12px;
	padding-top: 12px;
	padding-bottom: 12px;
}

.pt-strikezone-container {
	margin-top: 18px !important;
}

.d3-strike-zone-clickable {
	cursor: crosshair;
	background-color: white;
	border: 1px solid var(--gray);
	border-radius: 4px;
}

@media (max-width: 1023px) {
	.pt-location-stats-paper {
		font-size: 14px;
		padding: 2px 2px;
	}

	.pt-location-stat-value {
		display: block;
	}

	.pt-location {
		width: 384px !important;
		padding-left: calc(50% - 372px) !important;
	}
}
