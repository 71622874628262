.comps-title-paper {
	padding: 24px 0px;
	font-weight: bold;
}

.comps-title {
	font-size: 32px;
}

.comps-table-title {
	font-size: 24px;
	font-weight: bold;
	padding: 24px 0px 12px 24px;
}

.comps-table-add {
	text-align: right;
	padding: 24px 24px 12px 24px;
}
