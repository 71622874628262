.app-bar-logo {
	width: auto;
	max-height: 25px;
}

@media only screen and (max-width: 1074px) {
	#screen-large {
		display: none;
	}
}

@media only screen and (min-width: 1075px) {
	#screen-small {
		display: none;
	}
}

.sidenav-drawer-list {
	width: 200px;
}
