.outing-locations-pitch-circle {
	cursor: pointer;
}

.outing-locations-pitch-chip-FB {
	background-color: #e70b18 !important;
	color: white !important;
}

.outing-locations-pitch-chip-CB {
	background-color: #2326fa !important;
	color: white !important;
}

.outing-locations-pitch-chip-SL {
	background-color: #118a14 !important;
	color: white !important;
}

.outing-locations-pitch-chip-CH {
	background-color: #c8c72a !important;
	color: white !important;
}

.outing-locations-pitch-chip-CT {
	background-color: #21c8c7 !important;
	color: white !important;
}

.outing-locations-pitch-chip-2S {
	background-color: #fd8024 !important;
	color: white !important;
}

.outing-locations-pitch-chip-SPL {
	background-color: #653896 !important;
	color: white !important;
}

.outing-locations-pitch-chip-OTH {
	background-color: #000000 !important;
	color: white !important;
}

.outing-pitches-ab-name {
	color: rgba(0, 0, 0, 0.5);
	font-size: 12px !important;
}
