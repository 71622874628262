#pt-count-balls::after {
	content: "ALLS";
}

#pt-count-strikes::after {
	content: "TRIKES";
}

.pt-outing-state {
	margin-top: 18px;
}

.pt-outing-state-component {
	height: 75px !important;
	border: 1px solid var(--gray);
}

.pt-outing-state-name {
	font-size: 12px;
	padding-top: 4px;
}

.pt-outing-state-value {
	font-size: 36px;
}

.pt-runners-base {
	transform: rotate(45deg);
	padding-top: 5px;
}

#pt-base-grid-second {
	min-height: 40px;
	padding-top: 10px;
}

#pt-base-grid-third {
	min-height: 40px;
	padding-right: 20px;
}

#pt-base-grid-first {
	min-height: 40px;
	padding-left: 20px;
}

.pt-inning-grid {
	padding-right: 4px;
}

.pt-count-grid {
	padding: 0px 4px;
}

.pt-outs-grid {
	padding: 0px 4px;
}

.pt-runners-grid {
	padding-left: 4px;
}

.pt-inning-decrease {
	padding-top: 12px;
}

.pt-inning-increase {
	padding-top: 12px;
}

.pt-outs-decrease {
	padding-top: 12px;
}

.pt-outs-increase {
	padding-top: 12px;
}

.pt-runners-base.Mui-selected {
	background-color: var(--red) !important;
}

@media (max-width: 1023px) {
	#pt-count-balls::after {
		content: "";
	}

	#pt-count-strikes::after {
		content: "";
	}

	.pt-inning-grid {
		padding-right: 2px;
	}

	.pt-count-grid {
		padding: 0px 2px;
	}

	.pt-outs-grid {
		padding: 0px 2px;
	}

	.pt-runners-grid {
		padding-left: 2px;
	}

	#pt-base-grid-second {
		min-height: 30px;
		padding-top: 10px;
	}

	#pt-base-grid-third {
		min-height: 30px;
		padding-right: 14px;
	}

	#pt-base-grid-first {
		min-height: 30px;
		padding-left: 14px;
	}
}
