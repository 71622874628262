.pt-header-paper {
	border-bottom: 1px solid var(--gray);
}

.pt-header {
	width: 100% !important;
	padding-right: calc(50% - 500px) !important;
	padding-left: calc(50% - 500px) !important;
	padding-top: 12px;
	padding-bottom: 12px;
}

.pt-exit-button-div {
	padding-top: 10px;
}

#pt-header-pitcher-name {
	font-weight: bold;
}

.pt-header-select-batter {
	min-width: 100px;
}

@media (max-width: 1023px) {
	.pt-header {
		padding-right: calc(50% - 372px) !important;
		padding-left: calc(50% - 372px) !important;
	}
}
