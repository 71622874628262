.pt-outing-stats {
	height: 46px !important;
	border: 1px solid var(--gray);
}

.pt-outing-stat {
	padding-top: 6px !important;
}

.pt-outing-stat-value {
	font-size: 24px;
	font-weight: 500;
}

.pt-outing-stat-name {
	font-size: 24px;
	color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1023px) {
	.pt-outing-stat {
		padding-top: 0px !important;
	}

	.pt-outing-stat-value {
		font-size: 16px;
		font-weight: bold;
	}

	.pt-outing-stat-name {
		font-size: 14px;
		display: block;
	}
}
