.pt-pitch-type-toggle {
	width: 62px;
}

.pt-pitch-result-toggle {
	width: 98.3px;
}

.pt-data-entry-inputs {
	width: 95% !important;
	background-color: var(--white) !important;
}

.pt-notes-inputs {
	width: 100% !important;
	background-color: var(--white) !important;
}

#pt-pitch-result {
	margin-top: 24px;
}

#pt-pitch-result {
	margin-top: 24px;
}

.pt-pitch-type-div {
	width: 100% !important;
	margin-top: 24px !important;
}

.pt-pitch-type-btn-group {
	width: 100%;
}

.pt-notes-div {
	margin-top: 24px !important;
}

.pt-data-entry-div {
	margin-top: 24px;
}

@media (max-width: 1023px) {
	.pt-pitch-type-toggle {
		width: 50px;
	}

	.pt-pitch-result-toggle {
		width: 72.8px;
	}
}
