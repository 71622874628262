#pt-submit-buttons-div {
	margin-top: 25px;
}

#pt-end-at-bat-button {
	width: 95%;
	background-color: var(--white);
}

#pt-add-pitch-button {
	width: 95%;
}
