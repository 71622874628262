.pt-field-position-circle:hover {
	cursor: pointer;
}

.pt-field-position-circle:active {
	fill: #bbbbbb;
}

.pt-field-position-circle.fielder-selected {
	fill: var(--red);
}

.pt-field-position-text.fielder-selected {
	fill: var(--white);
	font-weight: bolder;
}

.pt-field-traj-div {
	padding-top: 6px;
}

.pt-field-ab-result-div {
	padding-top: 12px;
	width: 400px;
}

.pt-field-ab-result,
.pt-field-traj {
	width: 80px;
}

.ab-result-bottom {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

.ab-result-top {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	height: 72px;
}

.pt-field-modal-feedback {
	max-width: 425px !important;
}

.pt-field-svg {
	cursor: crosshair !important;
	background-color: white;
	border: 1px solid var(--gray);
	border-radius: 4px;
}
