.recent-competitions-card {
	min-height: 253px;
}

.recent-competitions-card-content {
	padding: 16px !important;
}

.recent-competitions-card-title {
	padding-top: 8px !important;
	padding-left: 0px !important;
	margin: 0px !important;
}

.recent-competitions-card-feedback {
	font-size: 18px;
	font-weight: 500;
}
