.pt-error-text-div {
	text-align: center;
	padding-top: 50px;
}

@media (min-width: 1024px) {
	/* Outing stats */
	.pt-outing-data {
		padding-right: calc(50% - 500px) !important;
		padding-left: 12px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	/* global */
	.inline-block {
		display: inline-block;
	}
	.pt-toggle-button.Mui-selected {
		background-color: var(--red) !important;
		color: var(--white) !important;
		font-weight: bolder;
	}

	.pt-toggle-button {
		background-color: var(--white) !important;
		border: 1px solid var(--gray) !important;
		color: black !important;
	}

	.pt-tracking-area {
		width: 100%;
	}
}

@media (max-width: 1023px) and (min-width: 768px) {
	.pt-toggle-button.Mui-selected {
		background-color: var(--red) !important;
		color: var(--white) !important;
		font-weight: bolder;
	}

	.pt-toggle-button {
		background-color: var(--white) !important;
		border: 1px solid var(--gray) !important;
		color: black !important;
	}

	.pt-outing-data {
		padding-right: calc(50% - 372px) !important;
		padding-left: 12px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	/* field modal */
	.pt-field-position-circle:hover {
		cursor: pointer;
	}

	.pt-field-position-circle:active {
		fill: #bbbbbb;
	}

	.pt-field-position-circle.fielder-selected {
		fill: var(--red);
	}

	.pt-field-position-text.fielder-selected {
		fill: var(--white);
		font-weight: bolder;
	}

	.pt-field-traj-div {
		padding-top: 6px;
	}
	.pt-field-ab-result-div {
		padding-top: 12px;
		width: 400px;
	}
	.pt-field-ab-result,
	.pt-field-traj {
		width: 80px;
	}
	.ab-result-bottom {
		border-top-left-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	.ab-result-top {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		height: 72px;
	}

	/* global */
	.inline-block {
		display: inline-block;
	}
	.pt-toggle-button.Mui-selected {
		background-color: var(--red) !important;
		color: var(--white) !important;
		font-weight: bolder;
	}

	.pt-toggle-button {
		background-color: var(--white) !important;
		border: 1px solid var(--gray) !important;
		color: black !important;
	}

	.pt-tracking-area {
		width: 100%;
	}
}
